import { FundFilled } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import Card from "components/cards/card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import profile from "images/profile.png";
import { Avatar } from "antd";
import ShadowButton from "components/buttons/shadowButton";
import { useLocation, useSearchParams } from "react-router-dom";
import { getCourses } from "../supa-base-client/courses";
import { useNavigate } from "react-router-dom";
export default function ProgramInfo(props) {
  const location = useLocation();
  const navigate = useNavigate();
  //const { catagory } = location.state || {};
  const [searchParams] = useSearchParams();
  const [error, setError] = useState({ error: true, message: "no error" });
  const [catagory, setCatagory] = useState(
    searchParams.get("category") || "combats"
  );

  const [courses, setCourses] = React.useState(null);
  useEffect(() => {
    async function fetchCourses() {
      try {
        console.log(catagory);
        const result = await getCourses();
        const res = result.filter(
          (course) => course.course_category === catagory
        );
        setCourses(res);
      } catch (err) {
        setError({ error: true, message: err });
      }
    }
    fetchCourses();
  }, [catagory]);

  const navigateToCourseInfo = (course) => {
    navigate(`/Courses/details?id=${course.id}`, {
      state: { course },
    });
  };
  return (
    <>
      <div>
        <span className="text-lato md:text-2xl flex justify-center mt-10">
          Time to Learn!
        </span>
        <div className="flex md:m-10 m-2">
          <div
            style={{ width: "100%" }}
            className="grid grid-cols-1 md:grid-cols-3 gap-6 m-0 w-full"
          >
            {courses &&
              courses.map((course) => (
                <Card
                  title={
                    <span className="text-lato md:text-xl text-sm">
                      {course.course_name}
                    </span>
                  }
                  body={
                    <>
                      <Avatar
                        size={60}
                        src={<img src={profile} alt="avatar" />}
                      />
                      <div className="flex flex-col ml-4">
                        <span className="text-lato text-medium">
                          {"Mentored By"}
                        </span>
                        <span className="text-lato text-medium">
                          {course.mentor}
                        </span>
                      </div>
                    </>
                  }
                  bottom={
                    <>
                      <span className="text-lato text-gray-500">
                        <FontAwesomeIcon icon={faStar} color="gold" />
                        4.5 (1645 ratings)
                      </span>
                      <span className="text-lato text-gray-500">
                        4K+learners
                      </span>
                      <span className="text-lato text-gray-500">2 Hrs</span>
                    </>
                  }
                  button={
                    <ShadowButton
                      buttonName={"Explore"}
                      onclick={() => navigateToCourseInfo(course)}
                      width={"50%"}
                    />
                  }
                />
              ))}
          </div>
        </div>
      </div>
    </>
  );
}
