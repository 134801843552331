import React, { useEffect, useState } from "react";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";
import PaymentFail from "./paymentFail";
import { useSearchParams } from "react-router-dom";
import { counter } from "@fortawesome/fontawesome-svg-core";
import { useCounter } from "pages/context/counterContext";

const PaymentStatus = () => {
  const navigate = useNavigate();
  const { removeItem, isExists } = useCounter();
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState(searchParams.get("status") || null);
  const [transaction_id, setTransactionId] = useState(
    searchParams.get("transactionId") || null
  );
  const goToCourses = () => {
    navigate("/courses");
  };
  const goToDashBoard = () => {
    navigate("/dashboard");
  };

  let message = "";

  useEffect(() => {
    if (status === "success") {
      const checkoutId = localStorage.getItem("checkout_course");
      if (checkoutId && isExists(checkoutId)) {
        removeItem(checkoutId, "cart");
        localStorage.removeItem("checkout_course");
      }
    }
  }, [status]);

  return (
    <>
      <Result
        status={status}
        title={
          status === "success" ? "Payment Successful!" : "Something went wrong!"
        }
        subTitle={
          status === "success"
            ? `Your Educational Transformation starts now!`
            : "If money has been deducted from your account. Please contact our support team."
        }
        extra={[
          <p>Your Transaction ID : {transaction_id}</p>,
          // <Button onClick={goToDashBoard} type="primary" key="console">
          //   Go To Dashboard
          // </Button>,
          <Button onClick={goToCourses} key="buy">
            Browse Courses
          </Button>,
        ]}
      />
    </>
  );
};
export default PaymentStatus;
