import supabase from "pages/supa-base-client/supabase";
import { getUserRegisteredCourses } from "pages/supa-base-client/user";
import { CaliculateDiscount } from "./caliculatePrice";
import { Descriptions } from "antd";

const apiURL = "https://api.knowvationlearnings.in";
//const apiURL = "http://192.168.0.122:5000";

export const formatDate = (d) => {
  d = new Date(d);
  return (
    ("0" + d.getDate()).slice(-2) +
    "-" +
    ("0" + (d.getMonth() + 1)).slice(-2) +
    "-" +
    d.getFullYear()
  );
};

export const getEmploymentType = (value) => {
  switch (value) {
    case "fulltime":
      return "Full Time";
    case "parttime":
      return "Part Time";
    case "freelance":
      return "Freelancer";
    case "contract":
      return "Contract";
    case "intern":
      return "Internship";
  }
  return value;
};

export const getRoleType = (value) => {
  switch (value) {
    case "wfh":
      return "Work from Home";
    case "wfo":
      return "Work from Office";
  }
  return value;
};

export async function getUserCourses() {
  try {
    const courses = await getUserRegisteredCourses(user.id);
    console.log(courses);
  } catch (err) {
    console.error(err);
  }
}

export async function getPaymentResponse(formData) {
  try {
    const response = await fetch(apiURL + "/api/v1/pay/payme", {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(formData),
    });
    if (!response.ok) {
      return null;
    }
    const data = await response.json();
    if (data.data.success) {
      return data.data.data.instrumentResponse.redirectInfo.url;
    }
  } catch (err) {
    console.error(err);
  }
}

export async function getCouponValidity(coupon) {
  try {
    const { data, error } = await supabase
      .from("coupons")
      .select("*")
      .eq("code", coupon);
    if (error) {
      console.error(error);
      return;
    }
    if (data[0].uses < data[0].max_uses) {
      if (data[0].type === "percentage")
        return { valid: true, percentage: data[0].amount };
      return { valid: true, amount: data[0].amount };
    }
    return { valid: false, percentage: 0 };
  } catch (err) {
    console.error(err);
  }
}

export function getNotification(api, type, message, description) {
  api[type]({
    message: `${message || "something went wrong"}`,
    description: `${description || "please try again after some time"}`,
  });
}
