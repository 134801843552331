import { faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function BlackCard({ batchNo,start }) {
  return (
    <>
      <div
        className="flex flex-row md:flex-col bg-black w-full items-center md:p-10 p-3 md:justify-center justify-between gap-6 mt-3"
        style={{ borderRadius: "10px" }}
      >
        <span className="text-poppins text-sm md:text-3xl flex flex-row items-center text-nowrap text-white">
          <FontAwesomeIcon icon={faClock} color="white" />
          New Batch
          <span className="text-sm md:text-3xl" style={{ color: "#7BF27C" }}>
            (#{batchNo})
          </span>
        </span>
        <span className="text-xl hidden md:flex" style={{ color: "#FFFFFF" }}>
          Starts from
        </span>
        <span className="md:text-4xl text-sm" style={{ color: "#7BF27C" }}>
          {start}
        </span>
      </div>
    </>
  );
}
