import supabase from "./supabase";

export const getCourses = async () => {
  const { data, error } = await supabase.from("courses").select("*");
  if (error) {
    console.error(error);
  }
  return data;
};

export const getSelectedCourse = async (id) => {
  if (!id) console.error("provide an id");
  const { data, error } = await supabase
    .from("courses")
    .select("*")
    .eq("id", `${id}`);
  if (error) {
    console.error("error");
    return;
  }
  return data;
};

export const getCourseModule = async (id, flag) => {
  const { data: courseModuleData, error: courseModuleError } = await supabase
    .from("course_module")
    .select("*")
    .eq("course_id", `${id}`)
    .order("order");
  if (courseModuleData) {
    if (flag) {
      const updatedCourseDetails = await Promise.all(
        courseModuleData.map(async (data) => {
          const { data: courseSubModuleData, error: courseSubModuleError } =
            await supabase
              .from("course_sub_modules")
              .select("*")
              .eq("course_module_id", `${data.id}`);
          if (courseSubModuleError) {
            console.error(courseSubModuleError);
          }
          data.sub_modules = courseSubModuleData;
        })
      );
    }

    return courseModuleData;
  }

  if (courseModuleError) console.error(courseModuleError);
};
