import React from "react";
import knowvationIcon from "../../images/KnowvationIcon.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faYoutube,
  faGithub,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import Constants from "../../util/constants";
export default function Footer() {
  return (
    <>
      <div className="flex pt-10 flex-col-reverse  md:flex-row md:gap-60 gap-10 ml-5 md:ml-10">
        <div>
          <div className="flex flex-col gap-5">
            <img
              src={knowvationIcon}
              alt="icon"
              className="w-3/6 hidden md:block"
            />
            <div className="flex flex-col gap-2">
              <span className="text-pop md:text-lg text-sm">
                Connect with us on Social Media Platforms
              </span>
              <div className="flex flex-row items-center gap-3">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={Constants.LINKEDININ_URL}
                  style={{
                    padding: "5px 10px 5px 10px",
                    backgroundColor: "#7BF27C",
                    borderRadius: "50%",
                  }}
                >
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={Constants.INSTAGRAM_URL}
                  style={{
                    padding: "5px 10px 5px 10px",
                    backgroundColor: "#7BF27C",
                    borderRadius: "50%",
                  }}
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={Constants.TWITTER_URL}
                  style={{
                    padding: "5px 10px 5px 10px",
                    backgroundColor: "#7BF27C",
                    borderRadius: "50%",
                  }}
                >
                  <FontAwesomeIcon icon={faXTwitter} />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={Constants.GITHUB_URL}
                  style={{
                    padding: "5px 10px 5px 10px",
                    backgroundColor: "#7BF27C",
                    borderRadius: "50%",
                  }}
                >
                  <FontAwesomeIcon icon={faGithub} />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={Constants.YOUTUBE_URL}
                  style={{
                    padding: "5px 10px 5px 10px",
                    backgroundColor: "#7BF27C",
                    borderRadius: "50%",
                  }}
                >
                  <FontAwesomeIcon icon={faYoutube} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row md:gap-20 gap-4 text-nowrap">
          <img src={knowvationIcon} alt="icon" className="w-3/4 md:hidden" />
          <div className="flex flex-col">
            <span className="text-pop md:text-xl text-sm">Products</span>
            <div className="flex flex-row md:flex-col md:gap-3 gap-1">
              <Link to="/Courses?category=combats">
                <span className="text-gray-500 text-poppins md:text-sm text-xs">
                  Courses
                </span>
              </Link>
              <Link to="/Events">
                <span className="text-gray-500 text-poppins md:text-sm text-xs">
                  Events
                </span>
              </Link>
              <Link to="/Services">
                <span className="text-gray-500 text-poppins md:text-sm text-xs">
                  Services
                </span>
              </Link>
              <Link to="/Careers">
                <span className="text-gray-500 text-poppins md:text-sm text-xs">
                  Careers
                </span>
              </Link>
              <Link to="/Blogs">
                <span className="text-gray-500 text-poppins md:text-sm text-xs">
                  Blogs
                </span>
              </Link>
              {/* <Link to="https://icube.knowvationlearnings.in" target="_blank" rel="noreferrer">
								<span className="text-gray-500 text-poppins md:text-sm text-xs">
									iCube
								</span>
							</Link> */}
            </div>
          </div>
          <div className="flex flex-col">
            <span className="text-pop md:text-xl text-sm">Support</span>
            <div className="md:flex flex-row md:flex-col md:gap-3 gap-1 flex-wrap">
              <Link to="/privacy">
                <span className="text-gray-500 text-poppins md:text-sm text-xs">
                  Privacy Policy
                </span>
              </Link>
              <Link to="/terms">
                <span className="text-gray-500 text-poppins md:text-sm text-xs">
                  Terms & Conditions
                </span>
              </Link>
              <Link to="/refund">
                <span className="text-gray-500 text-poppins md:text-sm text-xs">
                  Refund Policy
                </span>
              </Link>
            </div>
          </div>
          <div className="flex flex-col">
            <span className="text-pop md:text-xl text-sm">Contact</span>
            <div className="flex flex-col md:gap-3 gap-1">
              <span className="text-gray-500 text-poppins md:text-sm text-xs">
                2-6-1/142/143,
              </span>
              <span className="text-gray-500 text-poppins md:text-sm text-xs">
                Mahalakshmi Nagar, Cherlapalli,
              </span>
              <span className="text-gray-500 text-poppins md:text-sm text-xs">
                Hyderabad - 500051
              </span>
              <span className="text-gray-500 text-poppins md:text-sm text-xs">
                Info@knowvationlearnings.in
              </span>
              <span className="text-gray-500 text-poppins md:text-sm text-xs">
                Ph: +91 939 893 8887, 924 779 0184
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
